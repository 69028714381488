import {
  ConversationListItem,
} from '../../styles';
import { Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import Icon from '../../../../components/atoms/Icon';
import { fetchChatSessions, generateChatSessionId, getSessionChatHistories, setActiveCitation, setFetching, setSessionId, successAction } from '../../../../store/actions/aceActions';
import { formModalSetupActionPopUp } from '../../../../store/appState/popUp/formModal/formModalAction';
import { useAppSelector } from '../../../../hooks/hooks';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ChatSessionI } from '../../askAce.type';
import { getSessionId } from '../../../../utils/reduxOutside';
import { NEW_CHAT, HISTORY, TEXT_ACE_ENTERPRISE } from '../../../../utils/constant';
import { HistoryHeader, SessionListWrapper } from './styles';
import { getUserType } from '../../../../utils/storage';

export const SessionList = () => {
  const theme = useTheme();
  const location = useLocation();
  const currentPage = location.pathname.split('/')[2];
  const dispatch = useDispatch();
  const { chatSessions, chatSessionsPage, isStreaming, isLoading, isFetching }: any = useAppSelector(state => state.aceReducer);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeChatSession, setActiveChatSession] = useState<null | ChatSessionI>(null);
  const isAceLoading = isStreaming || isLoading;
  const isDisabledAccess = currentPage === 'enterprise' && getUserType() !== TEXT_ACE_ENTERPRISE

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const chatSessionHandler = useCallback(async () => {
    if (isDisabledAccess) {
      return dispatch(fetchChatSessions([]));
    };
  }, [currentPage]);

  useEffect(() => {
    chatSessionHandler();
  }, [location]);

  const renderConversationMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleCloseMenu}
      >
        <div
          style={{ width: 100, height: '100%' }}
        >
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              dispatch(
                formModalSetupActionPopUp('RENAME_CHAT_SESSION', {
                  conversation: activeChatSession,
                  data: chatSessions
                })
              );
            }}
          >
            Rename
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              dispatch(
                formModalSetupActionPopUp('CONFIRMATION_DELETE_CHAT_SESSION', {
                  conversation: activeChatSession,
                  data: chatSessions
                })
              );
            }}
          >
            Delete
          </MenuItem>
        </div>
      </Menu>
    )
  }

  const onSelectConversation = (sessionId: string, page: number) => {
    dispatch(setActiveCitation(null));
    dispatch(setSessionId(sessionId));
    dispatch(getSessionChatHistories(page ?? chatSessionsPage.page, 5, sessionId, currentPage.toUpperCase()));
  }

  const handleNewChat = () => {
    dispatch(setActiveCitation(null));
    dispatch(generateChatSessionId(currentPage.toUpperCase()))
    dispatch(successAction([]));
    dispatch(setFetching(true));
  }

  return (
    <>
      <HistoryHeader>
        <Typography variant="h5" paragraph={true}>
          {HISTORY}
        </Typography>
        <Typography
          variant="h5"
          paragraph={true}
          color={isAceLoading || isDisabledAccess || isFetching ? theme.palette.text.disabled : theme.palette.primary.main}
          onClick={() => isAceLoading || isDisabledAccess || isFetching ? undefined : handleNewChat()}
          style={{ cursor: isAceLoading || isDisabledAccess || isFetching ? 'auto' : 'pointer' }}
        >
          {NEW_CHAT}
        </Typography>
      </HistoryHeader>
      <SessionListWrapper>
        {!chatSessions.length ? <Typography variant="body2" textOverflow={'ellipsis'} color="#A3B3C2" style={{ padding: "0px 16px" }} >
          No Conversation
        </Typography> : chatSessions.map((item: ChatSessionI, idx: number) => {
          return (
            <ConversationListItem key={`session_${idx}`} isActive={getSessionId() === item.id}>
              <Typography variant="body2" textOverflow={'ellipsis'} style={{ cursor: 'pointer' }} onClick={() => onSelectConversation(item.id, 0)}>
                {item.title.length > 22 ? item.title.substring(0, 21) + ' ...' : item.title}
              </Typography>
              <Icon icon="moreVertical" onClick={(e: React.MouseEvent<HTMLElement>) => {
                setActiveChatSession(item);
                handleOpenMenu(e);
              }} />
            </ConversationListItem>
          );
        })}
      </SessionListWrapper>
      {renderConversationMenu()}
    </>
  );
};
