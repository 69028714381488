import React from 'react'
import { AceCitationI, CitationType, SqlCitationI } from '../askAce.type'
import Text from '../../../components/atoms/Text'
import Icon from '../../../components/atoms/Icon'
import { CitationList, CitationMoreButton, CitationWrapper, DrawingItem, SourceItem } from '../styles'
import { CitationDetails } from './CitationDetails'
import { SqlCitationDetails } from './SqlCitationDetails'
import { TEXT_CLICK_HERE_SEE_LOCATION } from '../../../utils/constant'
import { CustomTooltip } from './styles'

const DEFAULT_CITATION_EXPAND = 3;

interface AceCitationPropsI {
  selectedCitation: number | null
  citations: AceCitationI[],
  sqlCitations: SqlCitationI[],
  setSelectedCitation: (index: number | null) => void,
  isExpanded?: boolean,
  setIsExpanded?: () => void
  onClickUrl?: (url: string) => void
  onClickImageUrl?: (citation: AceCitationI) => void
  onClickSqlCitation: (id: string) => void;
  setActiveCitation: (data: string) => void;
}

export const AceCitations = ({ selectedCitation, citations, sqlCitations, setSelectedCitation, isExpanded, setIsExpanded, onClickUrl, onClickImageUrl, onClickSqlCitation, setActiveCitation }: AceCitationPropsI) => {
  if (!citations && !sqlCitations) return null;
  return (
    <CitationWrapper>
      {!citations.length && !sqlCitations.length ? null : (
        <div id='source'>
          <Text variant="h5">
            Citation {`(${(citations?.length) + (sqlCitations?.length)})`}
          </Text>
        </div>
      )}
      <CitationList>
        {!citations?.length ? null : citations?.map((item: AceCitationI, index: number) => {
          if (isExpanded || index <= 2) {
            return (
              <React.Fragment key={`ace-citations-${item.source}-${item.subSection}-${index}`}>
                {item.type === CitationType.FIGURE ?
                  (
                    <CustomTooltip placement="bottom-end" title={(<React.Fragment>
                      <div onClick={() => setActiveCitation(item.sourceId)}>
                        <Text variant='body3'>{TEXT_CLICK_HERE_SEE_LOCATION}</Text>
                      </div>
                    </React.Fragment>)} arrow>
                      <DrawingItem key={index} onClick={() => setSelectedCitation(index)} active={index === selectedCitation}>
                        <Icon icon='drawing' />
                        <Text variant='body3' overflow='ellipsis'>{`${item.source} ${item.sourceYear ?? ''} | ${item.figureNumber} | ${item.subSection}`}</Text>
                      </DrawingItem>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip placement="bottom-end" title={(<React.Fragment>
                      <div onClick={() => setActiveCitation(item.sourceId)}>
                        <Text variant='body3'>{TEXT_CLICK_HERE_SEE_LOCATION}</Text>
                      </div>
                    </React.Fragment>)} arrow ><SourceItem key={index} onClick={() => setSelectedCitation(index)} active={index === selectedCitation}>
                        <Icon icon='typeBook' />
                        <Text variant='body3' overflow='ellipsis'>{`${item.source} ${item.sourceYear ?? ''} | ${item.subSection}`}</Text>
                      </SourceItem>
                    </CustomTooltip>)
                }
              </React.Fragment>
            )
          }
        })}
        {!sqlCitations?.length ? null : sqlCitations?.map((item: SqlCitationI, index: number) => {
          if (isExpanded || index <= 2) {
            return (
              <React.Fragment key={`ace-sqlCitations-${index}`}>
                <DrawingItem key={index} onClick={() => setSelectedCitation(index)} active={index === selectedCitation} isHcss>
                  <Icon icon='hcss' />
                  <Text variant='body3' overflow='ellipsis'>{`HCSS`}</Text>
                </DrawingItem>
              </React.Fragment>
            )
          }
        })}
        {!isExpanded && citations !== null && citations.length > DEFAULT_CITATION_EXPAND ? <CitationMoreButton onClick={setIsExpanded}><Text variant='body3'>{`+${citations !== null && citations?.length - 3} more`}</Text></CitationMoreButton> : null}
      </CitationList >
      <CitationDetails dataSource={selectedCitation !== null && citations !== null ? citations[selectedCitation] : null} onClose={() => setSelectedCitation(null)} onClickUrl={onClickUrl as any} onClickImageUrl={onClickImageUrl as any} />
      <SqlCitationDetails dataSource={selectedCitation !== null && sqlCitations !== null ? sqlCitations[selectedCitation] : null} onClose={() => setSelectedCitation(null)} onClickSqlCitation={onClickSqlCitation} />
    </CitationWrapper>
  )
}
