import { AceInput, AceRightSection, ChatList } from './components';
import { Divider, useMediaQuery } from '@mui/material';
import { LoadMoreContainer, MainContent, PageContainer } from './styles';
import { useEffect, useRef, useState } from 'react'
import { useConnectionCallback, useConnectionPauseHandler, useMessageCallback } from '../../utils/eventHandlers';

import { CalculationContent } from '../../components/organisms/hoc/elements';
import { MobileInfoPanel } from './components/rightSectionAce/MobileInfoPanel';
import { MobileSidebar } from '../../components/molecules/MobileSidebar';
import SideBar from '../../components/molecules/sidebar';
import { aceNav } from '../../components/organisms/hoc/aceNav';
import { addChatItem, getSessionChatHistories, setChatSessionsPage, setIsStreaming, setSessionId, setUserAgent } from '../../store/actions/aceActions';
import { getUserType } from '../../utils/storage';
import useAceStreaming from '../../hooks/useAceStreaming';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import Text from '../../components/atoms/Text';
import UseWindowDimensions from '../../utils/getWindows';
import './components/loading.css';
import { useAppSelector } from '../../hooks/hooks';
import { AceStateI } from '../../store/reducers/aceReducers';
import { AceFetching } from './components/AceFetching';

const AceWithStreaming = (props: any) => {
  const {
    isMinimize,
    isShowMobileSidebar,
    handleShowMobileSidebar,
    sidebar,
    handleTreeState,
    users,
    login,
    isShowMobileInfoPanel,
    handleShowMobileInfoPanel,
    handleResetChatHistories,
    isConsent,
  } = props;
  const { folderTree, selectedItem } = sidebar;
  const { userLimit } = users;
  const { userPhoto, userDetails, loading } = login;
  // const { chatSessionsPage, error, sessionId, isLoading, isStreaming, isFetching }: AceStateI = useAppSelector(state => state.aceReducer);
  const { chatSessionsPage, error, sessionId, isLoading, isStreaming, isFetching, hasAgent }: AceStateI = useAppSelector(state => state.aceReducer);
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = !useMediaQuery('(min-width:1080px)');
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { height } = UseWindowDimensions();
  const [isLoadingChatHistory, setIsLoadingChatHistory] = useState<boolean>(false);

  useEffect(() => {
    handleTreeState(getUserType() ?? 'REGULAR', pathname);
    dispatch(setSessionId(null));
    dispatch(setChatSessionsPage({
      page: 0,
      totalPage: 0
    }));
    dispatch(setUserAgent(false));
    // reset chat histories on componentWillUnmount
    return () => {
      handleResetChatHistories();
    }
  }, [])

  const aceAgentHandler = (delay?: number) => {
    setTimeout(() => { initAgent(sessionId as string) }, delay ?? 0)
  }

  const onOpen = useConnectionCallback({ error, isStreaming, sessionId, hasAgent }, dispatch, aceAgentHandler);

  // const onOpen = useConnectionCallback({ error, isStreaming, sessionId }, dispatch);
  const onMessage = useMessageCallback(dispatch, "");
  const [setConnectFn, onClose] = useConnectionPauseHandler({ isStreaming }, dispatch);

  const [connect, sendMessage, _, initAgent] = useAceStreaming(
    onOpen,
    onMessage,
    onClose,
  )

  // const [connect, sendMessage] = useAceStreaming(
  //   onOpen,
  //   onMessage,
  //   onClose,
  // )

  useEffect(() => {
    setConnectFn(connect);
    join();
  }, [setConnectFn, connect])

  useEffect(() => {
    if (sessionId) {
      aceAgentHandler();
    }
  }, [sessionId])

  const join = () => connect();
  const handleSend = () => {
    if (!inputRef.current?.value) return;
    dispatch(setIsStreaming(true))
    sendMessage({
      eventType: 'ASK',
      question: inputRef.current?.value as string,
      numberOfChatHistory: 0,
      sessionId: sessionId
    })
    dispatch(addChatItem(inputRef.current.value))
    inputRef.current.value = '';
  }

  const handleLoadChatHistory = async () => {
    try {
      if (isFetching || isLoadingChatHistory) return;
      setIsLoadingChatHistory(true);
      await dispatch(getSessionChatHistories(chatSessionsPage.page + 1, 5, sessionId as string, 'PUBLIC', true));
      setTimeout(() => {
        setIsLoadingChatHistory(false);
      }, 200)
    } catch (error: any) {
    }
  }

  return (
    <CalculationContent>
      {chatSessionsPage.totalPage > chatSessionsPage.page + 1 && (<LoadMoreContainer top={height * 0.1} onClick={handleLoadChatHistory} isLoading={isLoadingChatHistory}>
        {isLoadingChatHistory ? <div className="loadingChatHistories" /> : <Text variant="h4" style={{ cursor: isFetching ? 'auto' : 'pointer' }}>Load more conversation</Text>}
      </LoadMoreContainer>)}
      {!isMobile ?
        <>
          <SideBar isMinimize={isMinimize} view={false} />
          <Divider orientation="vertical" flexItem />
        </>
        : <MobileSidebar
          isOpen={isShowMobileSidebar}
          handleClose={handleShowMobileSidebar}
          headerTitle={'Back to Conversation'}
          data={folderTree}
          selectedItem={selectedItem}
          userData={{
            userLimit, userDetails, userPhoto, loading
          }}
        />
      }
      <PageContainer>
        <MainContent isCenter={isFetching}>
          {isFetching ?
            <AceFetching /> :
            <>
              <ChatList />
              <AceInput inputRef={inputRef} onSubmit={handleSend} isMobile={isMobile} isDisabled={isLoading || isStreaming} /> </>
          }
        </MainContent>
        <AceRightSection isConsent={!!isConsent} isMobile={isMobile} />
      </PageContainer>

      {isMobile &&
        <>
          <MobileInfoPanel
            userPhoto={userPhoto}
            isOpen={isShowMobileInfoPanel}
            handleClose={handleShowMobileInfoPanel}
            headerTitle={'Information'}
            isConsent={isConsent}
          />
        </>}
    </CalculationContent>
  )
}

export default aceNav(AceWithStreaming)
