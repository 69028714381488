import { AceCitationI, CitationType } from '../askAce.type'
import { CitationDetail, CitationDetailButton, CitationFigureDetail, CitationFigureInfo } from '../styles';
import Icon from '../../../components/atoms/Icon';
import Text from '../../../components/atoms/Text';
import StyledButton from '../../../components/atoms/Button';
import { ExternalLink } from '../../../assets/icons';
import { IconButton, Tooltip } from '@mui/material';
import { CITATION_FIGURE_INFO, DRAWING_NUMBER } from '../../../utils/constant';
import HTMLContent from '../../../components/molecules/HTMLContent';

interface CitationDetailsPropsI {
  dataSource: AceCitationI | null,
  onClose: VoidFunction;
  onClickUrl: (url: string) => void;
  onClickImageUrl: (url: AceCitationI) => void
}

export const CitationDetails = ({ dataSource, onClose, onClickUrl, onClickImageUrl }: CitationDetailsPropsI) => {
  if (!dataSource) return null;
  return (
    <>
      <CitationDetail isDrawing={dataSource?.type === CitationType.FIGURE}>
        <CitationDetailButton onClick={onClose}>
          <Icon icon='times' />
        </CitationDetailButton>
        <Text variant='h5'>
          <span>
            {dataSource.subSection}
          </span>
        </Text>
        <a style={{ cursor: "pointer", fontSize: 12, fontWeight: 'bold', color: '#118CFF', textDecoration: "underline" }} onClick={() => dataSource?.type === CitationType.FIGURE ? onClickImageUrl(dataSource) : onClickUrl(dataSource.url)}>
          <ExternalLink>
          </ExternalLink>
          <span>
            {'Open Citation'}
          </span>
        </a>
        <CitationFigureDetail>
          {dataSource?.type === CitationType.FIGURE ?
            <>
              <p>{`${DRAWING_NUMBER}: ${dataSource.figureNumber}`}</p>
              <p><HTMLContent
                text={dataSource?.figureDescription?.replace(/\n/g, '<br />') as string}
              /></p>
              <CitationFigureInfo>{CITATION_FIGURE_INFO}</CitationFigureInfo>
            </>
            : <div id="message"><HTMLContent
              text={dataSource.text.replace(/\n/g, '<br />')}
            /> </div>
          }
        </CitationFigureDetail>
      </CitationDetail>
    </>
  )
}
