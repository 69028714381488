import { useCallback, useState } from "react"
import { ConnectFN, Contextable, EventType, PauseHandlerHook } from "../hooks/useAceStreaming";
import { 
  AnswerEndHandler, 
  generateChatSessionId, 
  getCitationByChatHistory, 
  removeLatestChatItem, 
  setClassification, 
  setFetching, 
  setIsStreaming, 
  setLoading, 
  setSessionError, 
  setUserAgent, 
  updateAceAnswer,
  updateChatHistory } from "../store/actions/aceActions";
import { AppDispatch } from "../store";
import { getSessionId } from "./reduxOutside";
import { getIsConsents } from "./storage";
import { AceStateI } from "../store/reducers/aceReducers";
import { hexToUtf8 } from "../pages/askAce/ace.utils";

export const useConnectionCallback = ({ error, isStreaming, sessionId, hasAgent }: Partial<AceStateI>, dispatch: AppDispatch, fn?: (delay?: number) => void) => {
  const userConsent: Record<string, unknown> = getIsConsents();
  const isConsent = userConsent.isConsent;
  const callback = useCallback((ev: any) => {
    if (error) {
      // show error button
      dispatch(setSessionError(false));
      if (hasAgent && sessionId) fn && fn()
    }
    if (ev.type === 'open' && isConsent && !sessionId) {
      // generate new session ID on create a new connection or re-connect
      dispatch(generateChatSessionId(window.location.pathname.split('/')[2].toUpperCase()))
    }
    if (isStreaming) dispatch(setIsStreaming(false))
  }, [error, isConsent, sessionId, fn])
  return callback;
}

export const useMessageCallback = (dispatch: AppDispatch, companyId?: string) => {
  const callback = useCallback(
    (ev: MessageEvent<any>) => {
      const data: Contextable = JSON.parse(ev.data);
      const sessionId = getSessionId();
      switch (data.eventType) {
        case EventType.ANSWER_TEXT:
          dispatch(setLoading(false));
          dispatch(
            updateAceAnswer(
              hexToUtf8(data?.data as string) // this is to process Japanese text
                ?.replaceAll('<br>', '\n')) // this is to add a line break in the output
              );
          break;
        case EventType.ANSWER_END:
          dispatch(AnswerEndHandler())
          break;
        case EventType.CLASSIFICATION:
          dispatch(setClassification(data?.data as string));
          if (sessionId) {
            dispatch(updateChatHistory(companyId, sessionId));
          }
          break;
        case EventType.INIT_START:
          dispatch(setLoading(true))
          break;
        case EventType.INIT_END:
          dispatch(setLoading(false))
          dispatch(setFetching(false));
          // check weather the company has Chat-bot Assistant
          if (data.data?.includes('initialized')) dispatch(setUserAgent(true));
          break;
        case EventType.CHAT_HISTORY_ID:
          dispatch(getCitationByChatHistory(data.data as string))
          break;
      }
    },
    []
  )
  return callback;
}

export const useConnectionPauseHandler = ({ isStreaming }: Partial<AceStateI>, dispatch: AppDispatch): PauseHandlerHook => {
  const [connectFn, setConnectFn] = useState<ConnectFN>(
    null as unknown as ConnectFN
  )

  const onClose = useCallback((ev) => {
    dispatch(setSessionError(true))
    if (isStreaming) {
      console.info('unexpected disconnection while streaming answer', ev);
      setTimeout(() => {
        dispatch(removeLatestChatItem());
      }, 500)
    } else {
      console.info('unexpected disconnection', ev);
    }
    if (connectFn) setTimeout(connectFn, 3000);
  }, [
    connectFn,
    isStreaming,
  ])

  const registerConnectFunction = useCallback((fn: ConnectFN) => {
    console.info('connecting to ace streaming...')
    setConnectFn(() => fn); // register connect function
  }, []);

  return [registerConnectFunction, onClose as any];
}